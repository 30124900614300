import "./styles.css";
import "./cv.css";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

export default function App() {
  return (
    <div className="cv">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
        rel="stylesheet"
      />
      <div className="header">
        <Typography variant="h4">
          Giuseppe Giulio <span style={{ opacity: "0.5" }}>Caponetto</span>
        </Typography>
        <div className="links">
          <Typography variant="body1" style={{}}>
            github.com/ggcaponetto | linkedin.com/in/ggcaponetto | +41 79 305 52
            98 | Dorfmattweg 2, 3075 Rüfenacht (Bern, Switzerland)
          </Typography>
        </div>
      </div>
      <br />
      <Divider />
      <br />
      <div className="body">
        <div className="col">
          <div className="row">
            <Typography variant="h5" style={{}}>
              EDUCATION
            </Typography>
            <Typography variant="h6" style={{}}>
              Bachelor of Computer Science
            </Typography>
            <div>
              2011 - 2015
              <br />
              University of Camerino, Italy.
            </div>
            <Typography variant="h6" style={{}}>
              Experimental Scientific Lyceum
            </Typography>
            <div>
              2005 - 2011
              <br />
              Ascoli Piceno, Italy.
              <br />
              Secondary school specialized in computer science, literature,
              mathematics, physics, philosophy and Latin.
            </div>
            <Typography variant="h6" style={{}}>
              Languages
            </Typography>
            <div>
              English (C1) | German (Second mother tongue) | Italian (Mother
              tongue)
            </div>
          </div>
          <br />
          <div className="row">
            <Typography variant="h5" style={{}}>
              SKILLS
            </Typography>
            <Typography variant="h6" style={{}}>
              TECHNICAL SKILLS
            </Typography>
            <div>
              Javascript (es{(new Date()).getFullYear()})/TypeScript • PostgreSQL • MongoDB • CouchDB • React • Angular •
              Docker • Microservices • AWS/Azure/Exoscale • Linux • PWAs/TWAs •
              CI/CD • Cypress • Webpack/Rollup
            </div>
            <Typography variant="h6" style={{}}>
              SOFT SKILLS
            </Typography>
            <div>
              Effective communication • Collaboration • Idea exchange •
              Self-management • Persistence in problem solving • Logical
              reasoning • Experimenting • Agile • Startup bootstrapper • Fast
              learner
            </div>
            <Typography variant="h6" style={{}}>
              OTHER SKILLS AND INTERESTS
            </Typography>
            <div>
              Game Development (Babylon.js / Three.js / Godot) • Advanced Audio Editing / DSP •
              Blockchain (Cardano) • Basic CAD and 3D Modeling
            </div>
          </div>
          <br />
          <div className="row">
            <Typography variant="h5" style={{}}>
              HACKATHONS
            </Typography>
            <Typography variant="h6" style={{}}>
              PLAY-BERN 2021, 2020
            </Typography>
            <div>
              Winner of the 2020 and 2021 editions with the self made games
              "Angry Boss" and "Palle" (RT Multiplayer Physics with Socket.io).
            </div>
            <Typography variant="h6" style={{}}>
              ENERGY DATA HACKDAYS
            </Typography>
            <div>
              Used the data provided by the SBB to determine the most profitable
              buildings in their portfolio for photovoltaic energy production.
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <Typography variant="h5" style={{}}>
              WORK EXPERIENCE
            </Typography>
            <Typography variant="h6" style={{}}>
              Frontend Engineer
            </Typography>
            <div>
              November 2017 - Present
              <br />
              geoimpact AG. Built the React application "SwissEnergyPlanning"
              and "EnergieReporter" to help Switzerland in the transition to
              renewable energies using Maps and GIS.
            </div>
            <Typography variant="h6" style={{}}>
              Fullstack Software Engineer
            </Typography>
            <div>
              2015 - 2017
              <br />
              Wiloca LLC. Built a React and React Native application
              "FoodiesTrip" to fight fake reviews in restaurants with GPS.
            </div>
            <Typography variant="h6" style={{}}>
              Frontend Engineer
            </Typography>
            <div>
              May 2015 - August 2015
              <br />
              E-Lios LLC. Built the web application "SHELL": Secure Home for an
              Extraordinary Long Living.
            </div>
          </div>
          <br />

          <div className="row">
            <Typography variant="h5" style={{}}>
              TECHNICAL PROJECTS
            </Typography>
            <Typography variant="h6" style={{}}>
              i18next-ai-keyscan
            </Typography>
            <div>
              An unofficial i18next CLI utility to extract labels from any source code, in any format with the help of OpenAI.<br />
              <a href={"https://github.com/ggcaponetto/i18next-ai-keyscan"}>Github: i18next-ai-keyscan</a>
              <br />I am the author and maintainer of this open source
              repository.
            </div>
            <Typography variant="h6" style={{}}>
              MUI-GOTIT
            </Typography>
            <div>
              A Material UI library to transform the declarative Snackbar API
              into an imperative one. <a href={"https://github.com/ggcaponetto/mui-gotit"}>Github: mui-gotit</a>
              <br />I am the author and maintainer of this open source
              repository.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
